
import React from 'react';

const DataContext = React.createContext({data:null,set:(_data)=>{console.log(_data)}});
export default DataContext;

export const PageContext = React.createContext({
  page:null, 
  waiting:false, 
  set:(_data)=>{console.log(_data)}
});



let appData = null;
export function SetAppData(data){
  appData = data;
}

export function GetAppData(data){
  return appData;
}