
import { useContext } from 'react';
import DataContext, { PageContext } from '../models/AppData';
import classes from './Footer.module.css';
import { useNavigate } from "react-router-dom";
import icon_innovativ from './../_assets/icons/innovativ-blue.svg';
import icon_kompetent from './../_assets/icons/kompetent-blue.svg';
import icon_neugierig from './../_assets/icons/neugierig-blue.svg';
import icon_startklar from './../_assets/icons/startklar-blue.svg';
import icon_verlaesslich from './../_assets/icons/verlaesslich-blue.svg';



function Footer() {
  const pageCtx = useContext(PageContext);
  const dataCtx = useContext(DataContext);
  const data = dataCtx.data;
  const navigate = useNavigate();


  const getIcon = (slug)=>{
    if(slug === "raum-1")
      return icon_startklar;
    if(slug === "raum-2")
      return icon_innovativ;
    if(slug === "raum-3")
      return icon_neugierig;
    if(slug === "raum-4")
      return icon_kompetent;
    if(slug === "raum-5")
      return icon_verlaesslich;

    return null;
  }

  const getIconScale = (slug)=>{
    if(slug === "raum-1")
      return "50%";
    if(slug === "raum-2")
      return "40%";
    if(slug === "raum-3")
      return "37%";
    if(slug === "raum-4")
      return "50%";
    if(slug === "raum-5")
      return "45%";

    return "50%";
  }
  const clicked = (page) =>{
    if(page.info)
      navigate(page.slug+"/0/0");
    else
      navigate(page.slug+"/1/1");
    //pageCtx.setWaiting(page.IsParent());
    
    //pageCtx.set(page);
  }

  
  return (
    <div className={classes.Footer}>
      <div className={classes.info_icons}>
        {
          data?.footerPages[0].children.map(page=>
            {
              return <div key={page.id} onClick={()=>clicked(page)}>{page.title}</div>
            }
          )
        }
      </div>
      <div className={classes.icons}>
        {
          data?.pages.map(page=>
            <div 
              key={page.id} 
              onClick={()=>clicked(page)} 
              
              title={page.title}
              className={page.id === pageCtx?.page?.page?.id || page.id === pageCtx?.page?.page?.parent?classes.active:""}
            >
              <span>{page.title}</span>
              <div style={{
                '--mask':`url(${getIcon(page.slug)})`,
                '--customScale':getIconScale(page.slug)
                }}></div>
            </div>
          )
        }
        
      </div>
    </div>
  );
}

export default Footer;
