
import { useContext } from 'react';
import DataContext, { PageContext } from '../models/AppData';
import classes from './PageView.module.css';
import icon_close from './../_assets/icons/menuClose.svg';

import icon_left from './../_assets/icons/arrowLeft.svg';
import icon_right from './../_assets/icons/arrowRight.svg';

import { SendEvent, ReactEventTypes } from './../ReactEvents';
import { useNavigate } from 'react-router-dom';


function PageView() {
  const pageCtx = useContext(PageContext);
  const dataCtx = useContext(DataContext);
  const page =  pageCtx?.page?.page;
  const navigate = useNavigate();
  
  const getNextChildIndex = (direction, page) => 
  {
    if(direction === "left"){
      if(page.lastActiveChildIndex != null && page.lastActiveChildIndex>0)
        return page.lastActiveChildIndex-1;
      else 
        return page.children.length-1;

    }else if(direction === "right"){
      
      if(page.lastActiveChildIndex != null && page.lastActiveChildIndex<page.children.length-1)
        return page.lastActiveChildIndex+1;
      else 
        return 0;
    }
  }

  const arrowClicked = (direction) =>{
    let parent = page;

    if(page?.parent){
      parent = dataCtx.data.Search(page.parent)
    }

    if(parent || page?.IsParent() && page.children?.length>0){
      let _page = parent?parent:page;
      const childIndexToSHow = getNextChildIndex(direction, _page);
      
      const subPage = _page.children[childIndexToSHow];
      _page.lastActiveChildIndex = childIndexToSHow;
      console.log(childIndexToSHow, '/',_page.children?.length, subPage.slug);
      navigate("/"+subPage.slug+"/1/1");

      //SendEvent(ReactEventTypes.action, {scope:"ANIMATION_START", value:subPage.slug})
    }
  }

  const getArrow = (id) => {
    
    if(page?.info)
      return null;

    const parent = dataCtx.data.Search(page?.parent)
    if(!parent && (!page?.IsParent() || !(page?.children?.length>0)))
      return null;
    if(id === "left"){
      
      return <div className={classes.icon + " " + classes.left} onClick={()=>{arrowClicked("left")}}>
        <span>left</span>
        <div style={{'--mask':`url(${icon_left})`}}></div>
      </div>
    }else if(id === "right"){
      return <div className={classes.icon + " " + classes.right} onClick={()=>{arrowClicked("right")}}>
        <span>right</span>
        <div style={{'--mask':`url(${icon_right})`}}></div>
      </div>
    }
  }

  const closeClicked = () =>{
    if(page.IsParent())
      navigate("/");
      
    else{
      //console.log(page, dataCtx.data)
      if(page.parent === dataCtx.data.footerPages[0].id){
        //console.log("footerpages")
      
        navigate("/");  
      }
      else if(dataCtx.data.pages.find(pp=>pp.id===page.parent)){
        //console.log("3d pages", dataCtx.data.Search(page.parent))
        navigate("/"+dataCtx.data.Search(page.parent).slug+"/0/0");
      }
    }
  }

  const getPageTitle = (page) =>{
    if(page?.info)
      return page?.title;

    if(page?.parent){
      const parent = dataCtx.data.Search(page.parent)
      
      if(parent)
        return parent.title;
    }

    if(page?.IsParent())
      return page.title;
    else
      return page?.title;
  }
  const getPageSubTitle = (page) =>{
    if(page?.info)
      return null;

    if(page?.parent){
      const parent = dataCtx.data.Search(page.parent);    
      if(parent)
        return page?.title;
    }
    return null;
  }
  
  
  const hasContent = page?.content && page?.content.length>0 ? true : false;
  
  
  if(hasContent && !pageCtx?.page?.waitingAnimation)
    document.getElementById("threejs").classList.add("half");
  else
    document.getElementById("threejs").classList.remove("half");

  
  return (
    <div className={classes.PageView+" "+(page && !pageCtx?.page?.waitingAnimation ?classes.opened:null)}>
      {<div className={classes.icon} onClick={closeClicked}>
        <span>close</span>
        <div style={{'--mask':`url(${icon_close})`}}></div>
      </div>}
      <div className={classes.content + " " + (hasContent?classes.hasContent:"") }>
        {
          <div className={classes.overlays}>
            <div className={classes.mainTitle}>
              <h1 key={getPageTitle(page)}>{getPageTitle(page)}</h1>
              {getPageSubTitle(page) && <h2>{getPageSubTitle(page)}</h2>}
            </div>
            
            {getArrow("left")}
            {getArrow("right")}
          </div>
        }
        {hasContent && 
          <div className={classes.scroll}>
            <div 
              dangerouslySetInnerHTML={{__html:!pageCtx?.page?.waitingAnimation ? page?.content:""}} 
              className={classes.externalContent + " " +"PageViewExternal"+ " " + page.slug}>            
            </div>
          </div>
        }
      </div>
      
      
    </div>
  );
}

export default PageView;
