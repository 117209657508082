import { useContext } from 'react';
import classes from './NetApp.module.css';
import Drawer from './Drawer/Drawer';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import DataContext, { PageContext } from './models/AppData';
import PageView from './PageView/PageView';
import { useEffect } from 'react';
import { ReactEventTypes, SendEvent } from './ReactEvents';
import { useNavigate } from 'react-router-dom';

function NetApp() {
  const dataCtx = useContext(DataContext);
  const pageCtx = useContext(PageContext);
  const navigate = useNavigate();

  const threeEventHandler = (e)=>{
    if(e.data?.scope === "ANIMATION-STARTED"){
      if(pageCtx?.page?.page?.slug != e.data?.value){
        const page = dataCtx.data.Search(e.data?.value);
        if(page)
          navigate("/"+page.slug+"/1/0");
        else
          console.log("not found", page);
      }
    }

    if(e.data?.scope === "ANIMATION-FINISHED"){
      console.log("ANIMATION-FINISHED", e.data?.value)
      const page = dataCtx.data.Search(e.data?.value);
      //console.log(page)
      if(page)
        navigate("/"+page.slug+"/0/0");
      /*else 
        navigate("//0/0");*/

      /*if(pageCtx?.waiting && pageCtx?.page.slug === e.data?.value){
        const page = dataCtx.data.Search(e.data?.value);
        navigate("/"+page.slug+"/0/0")
        //pageCtx.setWaiting(false);
      }
      else if(pageCtx?.page?.slug != e.data?.value){
        const page = dataCtx.data.Search(e.data?.value);
        navigate("/"+page.slug+"/0/0")                
      }*/
    }
  }

  useEffect(()=>{
    document.addEventListener(ReactEventTypes.state, threeEventHandler);
    SendEvent(ReactEventTypes.action, {scope:"ANIMATION_START", value:pageCtx?.page?.page?.slug?pageCtx?.page?.page?.slug:"0"})
    return ()=>{      
      document.removeEventListener(ReactEventTypes.state, threeEventHandler);	
    }
  },  
  [pageCtx?.page?.page]);

  return (
    <div className={classes.NetApp}>
      <Header></Header>
      <Drawer></Drawer>
      <PageView page={pageCtx?.page}></PageView>
      <Footer></Footer>
    </div>
  );
}

export default NetApp;
