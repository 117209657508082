export function ParsePage(raw){
  const page = new Page(raw);
  if(page?.id && page?.status === "publish")
    return page;
  return null;
}

class Page {
  constructor(raw) {

    if(!raw)
      return null;

    this.id = raw.id;
    this.slug = raw.slug;
    this.status = raw.status;
    this.title = raw.title?.rendered;
    this.content = raw.content?.rendered;
    this.excerpt = raw.excerpt?.rendered;
    this.parent = raw.parent;
    this.menu_order = raw.menu_order;

    if(this.IsParent())
      this.children = [];
  }

  IsParent(){
    return this.parent <= 0;
  }

  SortChildren(){
    if(this.children){
      this.children.sort(pageSort);
      this.children.forEach(child=>child.SortChildren())
    }
  }
}


export function pageSort (a,b){
  if(a.menu_order === b.menu_order)
    return a.title.localeCompare(b.title);

  return a.menu_order>b.menu_order?1:-1
}