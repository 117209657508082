
import { useContext } from 'react';
import { PageContext } from '../models/AppData';
import classes from './Header.module.css';

import logo from './../_assets/icons/NetAachen.svg';
function Header() {
  const pageCtx = useContext(PageContext);
  //const page = pageCtx?.page;

  return (
    <div className={classes.Header}>
      <img className={classes.logo} src={logo} alt="logo"></img>
    </div>
  );
}

export default Header;
