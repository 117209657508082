import { useEffect, useState } from 'react';

import './App.css';
import DataContext, { PageContext } from './models/AppData';
import { pageSort, ParsePage } from './models/Page';
import NetApp from './NetApp';
import { useLocation, useNavigate  } from 'react-router-dom';
import { ReactEventTypes, SendEvent } from './ReactEvents';
import './Netaachen.css';

function App() {
  const [data, setData] = useState(null);
  const [activePage, setActivePage] = useState(null);
  const [activePageWaiting, setActivePageWaiting] = useState(false);
  const [threeLoaded, setThreeLoaded] = useState(null);
  const location = useLocation();
  const [pendingPathName, setPendingPathName] = useState(null);
  //const [data, dispatch] = useReducer(dataReducer, null);
  const navigate = useNavigate();

  window.three_dev = false;
  
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(()=>{
    if(!data)
      return;
    let parts = [];
    let path = location?.pathname;

    //console.log(path);

    if(path){
      if(path.length>1){
        if(path[0] === "/" && path[1]!="/")
          path = path.substr(1,path.length-1);
      }
      parts = path.split("/");
    }
    
    if(parts.length>0){
      const page = data.Search(parts[0]);
      //console.log(page);
      if(page?.IsParent()){
        //console.log(page, data.pages);
        //data.pages.forEach(_page=>{if(_page.id != page.id)page.lastActiveChildIndex = null})
      }
      setActivePage({
        page:page, 
        waitingAnimation:parts.length>1?parts[1] === "1":false, 
        startAnimation:parts.length>2?parts[2] === "1":false 
      });
    }
    
  },[location?.pathname, data])

  useEffect(() => {
    document.addEventListener(ReactEventTypes.state, threeEventHandler);
    // console.log("start listner");
    
    let path = location?.pathname;
    let parts = [];
    if(path){
      if(path.length>1){
        if(path[0] === "/" && path[1]!="/")
          path = path.substr(1,path.length-1);
      }
      parts = path.split("/");
    }
    
    if(parts.length>0 && parts[0] != ""){
      //console.log(parts, location?.pathname);
      navigate('/')
      setPendingPathName("/"+parts[0]+"/1/1");
    }

    return () => {
      document.removeEventListener(ReactEventTypes.state, threeEventHandler);	
    }
  }, []);

  
  useEffect(() => {
    if(!pendingPathName)
      return;
    document.removeEventListener(ReactEventTypes.state, threeEventHandler);	
    document.addEventListener(ReactEventTypes.state, threeEventHandler);
    
    return () => {
      document.removeEventListener(ReactEventTypes.state, threeEventHandler);	
    }
  }, [pendingPathName]);

  const threeEventHandler = (e)=>{
    
    if(e.data?.scope === "THREE-LOAD"){
      let loader = document.getElementById("preloader");
      let valueEl = document.getElementById("preloader-value");
      if(!loader){ 
        loader = document.createElement("div");
        loader.id = "preloader";
        document.getElementById("threejs").appendChild(loader);

        valueEl = document.createElement("div");
        valueEl.id = "preloader-value";
        loader.appendChild(valueEl);

        let spinner  = document.createElement("div");
        spinner.classList.add("spinner");
        loader.appendChild(spinner);
        
      }
      if(e.data?.value>=1){
        
        valueEl.innerHTML = "loaded<br/>starting...";
        
        loader.getElementsByClassName("spinner")[0].classList.add("loaded");
        setTimeout(()=>{
          
          SendEvent(ReactEventTypes.action, {scope:"ANIMATION_START", value:"intro"});
          
          if(true){
            setTimeout(()=>{
            loader.classList.add("loaded");
            setTimeout(()=>{loader.remove();},2000); 
            }, 3000);
          }
        }, 500);
      }else{
        valueEl.innerHTML = "loading...<br/>" + (100*e.data?.value).toFixed(1)+" %"
      }
    }
    if(e.data?.scope === "INTRO"){
    
      if(e.data.value === "FINISHED"){
        document.body.classList.add("introDone");
      
        if(pendingPathName){
          console.log("Pending path", pendingPathName);
          setPendingPathName(null);
          //TODO check intro finsihed time
          //it seems, that three is not loaded completly -> ugly hack set timeout
          setTimeout(()=>navigate(pendingPathName,{replace:true}), 5000);
          
        }

      }
    }

  }



  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  
  useEffect(()=>{
    if(!data){
      
      if(window.three_dev){
        console.log("DEV Mode activated - threejs resolution and fps might be a little bit low", ":)")
        console.log("Check window.three_dev in App.js");
      }

      //pages?per_page=100&parent=&_fields=id,title,content.rendered,slug,status,parent,excerpt.rendered,menu_order
      fetch(process.env.REACT_APP_API_URL+"pages?per_page=100&status=publish&_fields=id,title,content.rendered,slug,status,parent,excerpt.rendered,menu_order")
      //fetch(process.env.REACT_APP_API_URL+"pages?per_page=100")
        .then(res=>{return res.json()})
        .then(pages=>{
          if(pages && Array.isArray(pages)){
            let data = {__allpages:{}, pages:[], footerPages:[], lostPages:[]};
            pages.forEach(rawPage=>{
              const page = ParsePage(rawPage);

              let parser = new DOMParser();
              const doc = parser.parseFromString(page.content, 'text/html');
              const codes = doc.getElementsByTagName("code")
              if(codes.length>0){
                if(codes.item(0).innerHTML && codes.item(0).innerHTML.length>1){
                  //console.log("Three slug found", page)
                  //page.three_slug = codes.item(0).innerHTML;
                }
              }

                

              //"rendered": "\n<pre class=\"wp-block-code\"><code>R_L</code></pre>\n",
              if(!page)
                return null;
              data.__allpages[page.id] = (page);
              if(page.IsParent()){               
                if(page.slug === "infos")
                  data.footerPages.push(page);
                else
                  data.pages.push(page);                
              }
            })

            Object.values(data.__allpages).forEach(page=>{
              if(!page.indexed){
                if(!page.IsParent()){
                  //console.log(page.parent, data.__allpages[page.parent]);
                  if(data.__allpages[page.parent]){
                    if(!data.__allpages[page.parent].children)
                      data.__allpages[page.parent].children = []; 

                    if(data.__allpages[page.parent].slug === "infos")
                      page.info = true;
                    data.__allpages[page.parent].children.push(page);
                    
                  }else{
                    console.error("parent not found", page);                    
                    data.lostPages.push(page);
                  }

                }
              }
            });
            
            data.pages.sort(pageSort);
            data.pages.forEach(parentPage=>parentPage.SortChildren())
            data.footerPages.sort(pageSort);
            //SetAppData(data);
            data.Search = function (needle){
              return Object.values(this.__allpages).find(page=>page.slug === needle || page.id  === needle);
            }.bind(data)
            setData(data);
            
          }
        })
    } else{
      
      if(threeLoaded == null){
        
        const script = document.createElement("script");
        script.src = process.env.REACT_APP_THREEJS_URL+"/threeloader.js";
        script.async = true;
        script.type = 'module';
        
        document.body.appendChild(script);
        setThreeLoaded(true);
      }
    }
    
  },[data, threeLoaded]);

  if(!data){
    return "loading";
  }
  
  
  window.portrait = width<768;

  return (
    <div className={"App" + (window.portrait?" portrait":" landscape") }>
      
      <DataContext.Provider value={{ data: data, setData: setData }}>
        
        <PageContext.Provider value={{ 
          page: activePage, set: setActivePage, 
          waiting:activePageWaiting, setWaiting:setActivePageWaiting }}>
        <NetApp></NetApp>
        </PageContext.Provider>
        
      </DataContext.Provider>
      
    </div>
  );
}

export default App;
